<script>

import { required, requiredIf } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

import {
	commonMethods,
  propertyMethods,
} from "@/state/helpers";

/**
 * New Campaign  component
 */

export default {
	components: {
		Multiselect,
	},
	data() {
		return {
			submitted:false,
			strategy:{
        type: {id: 'one_strategy', label: this.$t('custom_strategies.type_one_strategy')},
        pageType: 'any',
        settings:{
          shuffle: false,
          exclude_recently_purchased: false,
          exclude_recently_viewed: false
        },
        slots:[]
      },
      types: [
        {id: 'one_strategy', label: this.$t('custom_strategies.type_one_strategy')},
        {id: 'mixed', label: this.$t('custom_strategies.type_mixed')},
        {id: 'list', label: this.$t('custom_strategies.type_list')},
        {id: 'ranking', label: this.$t('custom_strategies.type_ranking')}
      ],
      auxStrategy: null,
      recoStrategies:[],
      pageTypes: [
        {id: "product_detail", label: this.$t("personalizations.page_type_product_detail")},
        {id: "cart", label: this.$t("personalizations.page_type_cart")},
        {id: "checkout", label: 'Checkout'},
        {id: "category", label: this.$t("personalizations.page_type_category") },
        {id: "home", label: this.$t("personalizations.page_type_home") },
        {id: "product_listing", label: this.$t("personalizations.page_type_product_listing") },
        {id: "search", label: this.$t("personalizations.page_type_search") },
        {id: "any", label: this.$t("personalizations.page_type_any") }
      ],
      auxPageType: {
        id: "any", label: this.$t("personalizations.page_type_any")
      },
      affinity_Types:[]
		};
	},
	validations: {
		strategy: {
			name: {
				required,
			},
      strategy: {
        required: requiredIf((model)=> { return model.type?.id == 'one_strategy'}),
      },
			
		},
	},
	methods: {
		...commonMethods,
    ...propertyMethods,

    loadProperties(){
      let loader = this.$loading.show();
      const params = {
        q : `project=${localStorage.getItem("current_project")}`
      }
      this.getProperties(params).then((properties)=>{
        const property_products = properties.filter(p=> p.entity == 'product');
        this.affinity_Types = property_products.filter(p=> p.type== 'list');

				this.affinity_Types.push({
          type : 'product_category',
          name: this.$t("audiences.audience_conditions_fields_interaction_products_with_category"),
          column_name: 'product_category'
        });
      }).catch(()=>{

      }).finally(()=>{
        loader.hide();
      })
    },

    async loadRecoStrategies(){
      this.recoStrategies = await this.getRecommendationStrategies();

      if(this.auxPageType && this.auxPageType.id != 'any'){
        this.recoStrategies = this.recoStrategies.filter(s => (!s.pages_restricted || s.pages_restricted.length == 0) || (s.pages_restricted.length > 0 && s.pages_restricted.filter(p=> p === this.auxPageType.id).length == 0) )
      }
    },
    validateCustomStrategyData(){
			this.submitted = true;
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			}

      if(this.strategy.type.id == 'one_strategy'){
        this.strategy.strategy = this.auxStrategy;
      }else if(this.strategy.type.id == 'list'){
        this.strategy.pageType = 'any';
      }
      return this.strategy;
    },

    onStrategyChanged(){
      this.strategy.strategy = this.auxStrategy;
    },

    onPageTypeChanged(){
      this.strategy.pageType = this.auxPageType.id;
      this.loadRecoStrategies();
    },

    onStrategyTypeChanged(){
      if(this.strategy.type.id != 'one_strategy'){
        this.strategy.strategy = null;
        this.auxStrategy = null;
      }
        
    }

	},
	mounted() {
		this.loadRecoStrategies();
    this.loadProperties();
	},
	props: {},
	emits: []
};
</script>

<template>
	<div>
		<div class="row">
        <div class="col">
          <div class="mb-3">
            <label for="campaignName">{{$t('custom_strategies.name')}}*</label>
            <input
              id="campaignName"
              v-model="strategy.name"
              name="name"
              type="text"
              :placeholder="$t('custom_strategies.name_placeholder')"
              class="form-control"
              :class="{'is-invalid': submitted && $v.strategy.name.$error,}"
            />
            <div v-if="submitted && !$v.strategy.name.required" class="invalid-feedback">
              {{$t('custom_strategies.name_required')}}
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="this.strategy.type?.id != 'list'">
        <div class="col">
          <div class="mb-3">
            <label for="role">{{$t('custom_strategies.page_type')}}*</label>
            <multiselect 
              v-model="auxPageType"
              :options="pageTypes"
              :selectLabel="$t('common.select')"
              :deselectLabel="$t('common.deselect')"
              :multiple="false"
              :allow-empty="false"
              track-by="id" 
              label="label"
              :showNoResults="false"
              @input="onPageTypeChanged">
            </multiselect>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="mb-3">
            <label for="role">{{$t('custom_strategies.type')}}*</label>
            <multiselect 
              v-model="strategy.type"
              :options="types"
              :selectLabel="$t('common.select')"
              :deselectLabel="$t('common.deselect')"
              :multiple="false"
              :allow-empty="false"
              track-by="id" 
              label="label"
              :showNoResults="false"
              @input="onStrategyTypeChanged">
            </multiselect>
          </div>
        </div>
      </div>
      <div class="row" v-if="strategy.type?.id == 'one_strategy'">
        <div class="col">
          <div class="mb-3">
            <label for="role">{{$t('custom_strategies.strategy')}}*</label>
            <multiselect 
              v-model="auxStrategy"
              :options="recoStrategies"
              :selectLabel="$t('common.select')"
              :deselectLabel="$t('common.deselect')"
              :placeholder="$t('custom_strategies.strategy_placeholder')"
              :multiple="false"
              :allow-empty="false"
              track-by="id" 
              label="name"
              :showNoResults="false"
              :class="{'is-invalid': submitted && $v.strategy.strategy.$error,}"
              @input="onStrategyChanged">
            </multiselect>
            <div v-if="submitted && !$v.strategy.strategy.required" class="invalid-feedback">
              {{$t('custom_strategies.strategy_required')}}
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="strategy.type?.id == 'one_strategy' && strategy.strategy?.id=='user_affinity'">
        <div class="col">
          <div class="mb-3">
            <label for="role">{{this.$t("personalizations.experience_variable_strategy_affinity")}}*</label>
            <multiselect
                :options="affinity_Types" 
                v-model="strategy.strategy.affinity"
                selectLabel=""
                deselectLabel=""
                track-by="column_name"
                :placeholder="this.$t('personalizations.experience_variable_strategy_affinity_placeholder')"
                label="name"
                :allow-empty="false"
                openDirection="bottom"
              />
          </div>
        </div>
      </div>
  </div>
</template>

<style scoped>

</style>