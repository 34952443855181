<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import _debounce from 'lodash.debounce';
import EmptyList from "@/components/widgets/empty_list"
import NewCustomStrategy from "@/components/recommendations/new-custom-strategy";

import {
  strategyMethods
} from "@/state/helpers";

export default {
  page: {
    title: "Custom Strategies",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    EmptyList,
    NewCustomStrategy
  },
  data() {
    return {
      title: "",
      items: [],
			currentPage:1,
			perPage:10,
      rows:0,
			isLoading:false,
      strategies:[],
      confirmDelete:false,
      strategy: null,
      query:'',
      empty_list_config:{
            "title" : this.$t('custom_strategies.empty_title'),
            "linkButtonText" : this.$t("common.reset_search"),
            icon: "fa-cubes"
        },
        empty_config:{
            "title" : this.$t('custom_strategies.empty_title'),
            "subtitle" : this.$t('custom_strategies.empty_text'),
            "buttonText" : this.$t('custom_strategies.new'),
            icon: "fa-cubes"
        },
        isLocal: window.config.env == 'dev',
        showNewCustomStrategyModal: false,
        
    };
  },
  created(){
		this.debounceCustomStrategies = _debounce(this.searchCustomStrategies.bind(this), 1000);
	},
	mounted(){
		this.loadCustomStrategies();
  
  },
  methods:{
    ...strategyMethods,

    onRemoveCustomStrategy(strategy){
      this.strategy = strategy;
      this.confirmDelete = true;
    },
    onConfirmRemoveCustomStrategyClicked(){
      let loader = this.$loading.show();

      this.deleteCustomStrategy(this.strategy._id).then(()=>{
        this.currentPage=1;
        this.loadCustomStrategies();
        this.$notify({ type: 'success', text: this.$t('custom_strategies.remove_custom_strategy_success'),title:  this.$t('custom_strategies.title') });
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('custom_strategies.remove_custom_strategy_error'),title:  this.$t('custom_strategies.title') });
      }).finally(()=>{
        loader.hide();
      })
    },

		loadCustomStrategies(){
			
      let loader = this.$loading.show();
      this.isLoading = true;
      
      const params = {
				q : `where[project]=${localStorage.getItem("current_project")}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`
			}

			this.getCustomStrategies(params).then((result)=>{
        if(result&& result.data){
          this.strategies= result.data;
          this.rows= result.totalCount
				}
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('custom_strategies.get_custom_strategies_error'), title: this.$t('custom_strategies.title')   });
      }).finally(()=>{
        this.isLoading = false;
        loader.hide();
      });
    },
    
    searchCustomStrategies(query){
      
			let loader = this.$loading.show();
      this.isLoading = true;
      this.query = query;
      this.empty_list_config.subtitle =  this.$t("custom_strategies.empty_search_text").replaceAll('#', this.query);

      if(query){
        this.strategies=[];
        this.currentPage = 1;
				const params={
					q: `where[project]=${localStorage.getItem("current_project")}&where[name][$regex]=.*${query}.*&where[name][$options]=i&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
				}

        this.getCustomStrategies(params).then((result)=>{
          if(result&& result.data){
            this.strategies= result.data;
            this.rows= result.totalCount;
          }
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t('custom_strategies.get_custom_strategies_error'), title: this.$t('custom_strategies.title')   });
        }).finally(()=>{
          this.isLoading = false;
          loader.hide();
        });
      }else{
        this.isLoading = false;
        this.currentPage = 1;
        loader.hide();
        this.loadCustomStrategies();
      }
    },
		onCustomStrategyListPageClicked(){
			this.loadCustomStrategies();
		},	
    onEditCustomStrategyClicked(data){
      this.$router.push({
            path: `/custom-strategy?id=${data._id}`,
            }
        ).catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
        });
    },
    onCreateCustomStrategyClicked(){
      this.showNewCustomStrategyModal = true;
    },
    onResetSearchClicked(){
      this.query = '';
      this.loadCustomStrategies();
    },
    onNewCustomStrategyClicked(){
      this.showNewCustomStrategyModal = true
    },
    onChangeCustomStrategyStatus(data){
      let loader = this.$loading.show();
      const payload = {
        _id: data._id,
        status : !data.status
      }
      this.updateCustomStrategy(payload).then(()=>{
          this.$notify({ type: 'success', text: this.$t('custom_strategies.update_custom_strategy_success'),title:  this.$t('custom_strategies.title') });
          this.loadCustomStrategies();
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t('custom_strategies.update_custom_strategy_error'),title:  this.$t('custom_strategies.title') });
        }).finally(()=>{
          loader.hide();
        })
    },
    onConfirmNewCustomStrategyClicked(){

      const strategy = this.$refs.newCustomStrategy.validateCustomStrategyData();
      if(!strategy)
        return;

      const payload = {
        name: strategy.name,
        type : strategy.type.id,
        pageType: strategy.pageType,
        status: 0,
        settings: strategy.settings,
        project: localStorage.getItem('current_project')
      }
      
      if(strategy.type.id == 'one_strategy'){
        payload.strategy = strategy.strategy;
      }

      let loader = this.$loading.show();

      this.createCustomStrategy(payload).then(()=>{
        this.$notify({ type: 'success', text: this.$t("custom_strategies.create_custom_strategy_success"),title:  this.$t("custom_strategies.title") });

          this.showNewCustomStrategyModal = false;
          this.currentPage= 1;
          this.loadCustomStrategies();
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("custom_strategies.create_custom_strategy_error"),title:  this.$t("custom_strategies.title") });

      }).finally(()=>{
        loader.hide();
      })
    
  }
}
};
</script>

<template>
  <Layout>
    <PageHeader :title="this.$t('custom_strategies.title')" :items="items" />
    <div class="row">
      <div class = "col">
        <div class="card mb-3">
				<div class="card-body">
          <div class="row ">
            <div class="col-sm-4 d-inline-flex">
                <div class="search-box me-2 mb-0 d-inline-block">
                  <div class="position-relative">
                      <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('common.search')"
                      @input="debounceCustomStrategies($event.target.value)"
                      v-model="query"
                      />
                      <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
            </div>
            <div class="col text-sm-end">
              <button
                      type="button"
                      class="btn btn-primary mb-0 me-0"
                      @click="onNewCustomStrategyClicked"
                      v-if="strategies.length>0 || query!=''">
                  <i class="mdi mdi-plus me-1"></i> {{$t('custom_strategies.new')}}
              </button>
            </div>
          </div>
          <EmptyList :config="empty_list_config" v-if="!isLoading && strategies.length == 0 && query!=''" @onButtonClicked="onResetSearchClicked" class="mt-3 mb-3"/>
          <EmptyList :config="empty_config" v-if="!isLoading && strategies.length == 0 && query ==''" @onButtonClicked="onNewCustomStrategyClicked" class="mt-3 mb-3"/>
        </div>
          <div class="col-lg-12" v-if="!isLoading && strategies.length>0">
            <div>
              <div class="table-responsive">
                <table class="table align-middle table-nowrap table-hover mb-0">
                  <thead class="table-light">
                    <tr>
                      <th scope="col">{{$t('custom_strategies.name')}}</th>
                      <th scope="col" class="text-center">{{$t('custom_strategies.page_type')}}</th>
                      <th scope="col" class="text-center">{{$t('custom_strategies.status')}}</th>
                      <th scope="col" class="text-end"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="data in strategies" :key="data.id">
                      <td>
                        <h5 class="text-truncate font-size-14 mb-0 interact" v-on:click="onEditCustomStrategyClicked(data)">
                          <a class="text-dark">{{
                            data.name
                          }}</a>
                        </h5>
                        <p class="text-muted mb-0 small interact" v-on:click="onEditCustomStrategyClicked(data)">{{ $t(`custom_strategies.type_${data.type}`) }}</p>
                      </td>
                      <td class="text-center">{{ $t(`personalizations.page_type_${data.pageType}`)}}</td>
                      <td class="text-center">
                        <span class="badge font-size-10"
                          :class="{
                          'bg-success': `${data.status}` == 1,
                          'bg-warning': `${data.status}` == 0}">
                            {{ data.status == 1 ? $t('custom_strategies.status_active') : $t('custom_strategies.status_inactive') }}
                        </span>
											</td>
                      <td class="text-end">
                        <i class="fas text-primary me-2 interact" :class="data.status == 1 ? 'fa-pause' : 'fa-play'" v-on:click="onChangeCustomStrategyStatus(data)"></i>
                        <i class="fas fa-edit text-success me-2 interact" v-on:click="onEditCustomStrategyClicked(data)"></i>
                        <i class="fas fa-trash-alt text-danger me-1 interact" v-on:click="onRemoveCustomStrategy(data)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3" v-if="rows > perPage">
          <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                          @input="onCustomStrategyListPageClicked"
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                      ></b-pagination>
                  </ul>
              </div>
          </div>
        </div>
        <b-modal v-model="confirmDelete" id="modal-center" centered :title="$t('common.confirm')" title-class="font-18" @ok="onConfirmRemoveCustomStrategyClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
            <p>{{ $t('custom_strategies.remove_custom_strategy') }}</p>
        </b-modal>
        <b-modal v-model="showNewCustomStrategyModal" :title="$t('custom_strategies.new')" :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')" @ok.prevent="onConfirmNewCustomStrategyClicked">
          <div>
            <NewCustomStrategy ref="newCustomStrategy"/>
          </div>
        </b-modal>
      </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
.interact {
  cursor: pointer;
}
</style>

<style>
#templates-modal .modal-body {
  background-color: var(--bs-body-bg);
}
</style>
